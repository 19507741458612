<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="30%" persistent>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="toolbar-color">
            <span class="headline ml-2"> Obervação {{ title }} </span>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-if="title == 'Lançamento'"
                    :value="data.obs_lanc"
                    disabled
                  ></v-textarea>
                  <v-textarea
                    v-if="title == 'Autorizado'"
                    :value="data.obs_auto"
                    disabled
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    title: {
      type: String
    },

    data: {
      type: Object
    }
  },

  watch: {
    LabelTitle(value) {
      return value;
    }
  },

  methods: {
    close() {
      this.$emit("closeModal");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
