<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :lastPage="nLastPage"
      @pageChange="fetchLancamento(filters, $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Consulta</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="1122"
            @click="(dialog = true), (labelBtn = 'Cadastrar')"
          />
          <FilterLancamento
            @selectedFilters="fetchLancamento((filters = $event))"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>
      <template v-slot:[`item.colaborador`]="{ item }">
        {{ item.id_colaborador }} - {{ item.colaborador | TitleCase }}
      </template>
      <template v-slot:[`item.indicador`]="{ item }">
        {{ item.id_indicador }} - {{ item.indicador }}
      </template>
      <template v-slot:[`item.mes_ref`]="{ item }">
        {{ `${item.mes_ref}/${item.ano_ref}` }}
      </template>
      <template v-slot:[`item.valor`]="{ item }">
        {{ item.valor | BrazilianCurrency }}
      </template>

      <template v-slot:[`item.obs_lanc`]="{ item }">
        <v-btn
          v-can-access="1123"
          @click="openModalObservacao(item, 'Lançamento')"
          icon
          color="orange"
          dark
        >
          <v-icon>mdi-message-reply-text</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.autorizado`]="{ item }">
        <v-chip v-if="item.autorizado != 'N'" color="success" dark>
          Sim
        </v-chip>
        <v-chip v-else color="error" dark>
          Não
        </v-chip>
      </template>

      <template v-slot:[`item.obs_auto`]="{ item }">
        <v-btn
          v-can-access="1123"
          @click="openModalObservacao(item, 'Autorizado')"
          icon
          color="primary"
          dark
        >
          <v-icon>mdi-message-reply-text</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-btn
          v-can-access="1123"
          @click="openModal(item)"
          icon
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>-->
        <IconBottom
          :name="'edit'"
          v-can-access="1123"
          @click="openModal(item)"
        />

        <v-btn
          v-can-access="1281"
          @click="openModalAutoLan(item)"
          icon
          color="primary"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <!--<v-btn
          v-can-access="1125"
          icon
          @click="deleteLancamento(item.id_lanc_manual)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->
        <IconBottom
          :name="'delete'"
          v-can-access="1125"
          @click="deleteLancamento(item.id_lanc_manual)"
        />
      </template>
    </BaseTable>
    <ModalLancamento
      :dialog="dialog"
      :dataForm="data"
      :labelBtn="labelBtn"
      @closeModal="(dialog = false), fetchLancamento()"
    />
    <ModalObservacao
      :dialog="dialogObservacao"
      :data="data"
      :title="titleModal"
      @closeModal="dialogObservacao = false"
    />
    <ModalAutorizaLancamento
      :dialog="dialogAutoLanc"
      :dataForm="data"
      @closeModal="(dialogAutoLanc = false), fetchLancamento()"
    />
  </div>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable.vue";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import FilterLancamento from "./FilterLancamento.vue";
import ModalLancamento from "./ModalLancamento.vue";
import ModalObservacao from "./ModalObservacao.vue";
import ModalAutorizaLancamento from "./ModalAutorizaLancamento.vue";
import comissao from "@/services/http/comissaoService";
import currencyMixin from "@/mixins/currencyMixin";
import BaseSegmento from "@/components/shared/BaseSegmento";

export default {
  name: "LancamentoManual",
  components: {
    BaseTable,
    RegisterBottom,
    FilterLancamento,
    ModalLancamento,
    ModalObservacao,
    BaseSegmento,
    ModalAutorizaLancamento,
    IconBottom
  },

  mixins: [currencyMixin],

  data() {
    return {
      loading: false,
      dialog: false,
      dialogAutoLanc: false,
      dialogObservacao: false,
      data: {},
      titleModal: "",
      search: "",
      labelBtn: "Cadastrar",
      filters: "",
      nLastPage: 0,
      headers: [
        { text: "Bandeira", value: "id_band" },
        { text: "Empresa", value: "apelido" },
        { text: "Colaborador", value: "colaborador" },
        { text: "Indicador", value: "indicador" },
        { text: "Mês Ref.", value: "mes_ref", align: "center" },
        { text: "Valor", value: "valor", align: "right" },
        { text: "Obs. Lançamento", value: "obs_lanc", align: "center" },
        { text: "Autorizado", value: "autorizado", align: "center" },
        { text: "Obs. Autorizado", value: "obs_auto", align: "center" },
        { text: "Ações", value: "actions", align: "center" }
      ],
      info: [],
      idColaborador: ""
    };
  },

  methods: {
    openModal(item) {
      this.dialog = true;
      this.labelBtn = "Editar";
      this.data = Object.assign({}, item);
    },
    openModalAutoLan(item) {
      this.dialogAutoLanc = true;
      this.data = item;
    },
    openModalObservacao(item, title) {
      this.dialogObservacao = true;
      this.titleModal = title;
      this.data = item;
    },
    async deleteLancamento(id) {
      try {
        await this.$swal.confirm(
          `Deseja deletar o lançamento ?`,
          "Essa ação não pode ser desfeita"
        );
        await comissao()
          .lancamentoManual(id)
          .delete({}, { notification: true });
        await this.fetchLancamento();
      } catch (e) {
        console.log(e);
      }
    },

    async fetchLancamento(filter, nPage = 1) {
      this.loading = true;
      let { data } = await comissao()
        .lancamentoManual()
        .show({
          page: nPage,
          ...filter
        });
      this.nLastPage = data.last_page;
      this.loading = false;
      this.info = data.data;
    }

    // refresh() {
    //   this.dialog = false;
    //   this.fetchLancamento();
    // }
  },

  mounted() {
    this.fetchLancamento();
  }
};
</script>
