<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="900" persistent>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="toolbar-color">
            <span class="headline ml-2">
              {{
                labelBtn != "Cadastrar"
                  ? "Editar Lançamento"
                  : "Cadastro de Lançamento Manual"
              }}
            </span>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <!-- segmento -->
                <v-col cols="12" sm="4">
                  <BaseSelect
                    prepend-inner-icon="mdi-file-table-box-multiple-outline"
                    clearable
                    single-line
                    label="Segmento"
                    item-text="descricao"
                    item-value="id_band"
                    service="sistemaService.tipoBandeiras"
                    v-model="form.id_band"
                    :filters="{ id_band: '2,4' }"
                    :isCompany="true"
                  />
                </v-col>
                <!-- Empresa -->
                <v-col cols="12" sm="4">
                  <BaseSelect
                    prepend-inner-icon="mdi-domain"
                    clearable
                    single-line
                    label="Empresas"
                    item-text="apelido"
                    item-value="id_empresa"
                    service="sistemaService.empresa"
                    v-model="form.id_empresa"
                    :isCompany="true"
                    :rules="[rules.required]"
                    :filters="{ id_band: form.id_band, calcula_comissao: 'S' }"
                  />
                </v-col>
                <!-- Colaboradores -->
                <v-col cols="12" sm="4">
                  <BaseSelect
                    prepend-inner-icon="mdi-account"
                    clearable
                    single-line
                    label="Colaborador"
                    item-text="colaborador"
                    item-value="id_colaborador"
                    service="sistemaService.colaboradorEmpresa"
                    v-model="form.id_colaborador"
                    :filters="{ id_empresa: form.id_empresa, ativo: 'S' }"
                    :rules="[rules.required]"
                  />
                </v-col>
                <!-- Indicadores -->
                <v-col cols="12" sm="5">
                  <BaseSelect
                    prepend-inner-icon="mdi-finance"
                    single-line
                    label="Indicadores"
                    item-text="indicador"
                    item-value="id_indicador"
                    clearable
                    v-model="form.id_indicador"
                    :filters="{ ativo: 'S' }"
                    service="sistemaService.indicadores"
                    :hasCode="true"
                    :rules="[rules.required]"
                  />
                </v-col>

                <!-- Tipo Dado -->
                <v-col cols="12" sm="3">
                  <BaseSelect
                    prepend-inner-icon="mdi-calendar-end"
                    service="comissaoService.tipoDados"
                    chips
                    label="Tipo Dado"
                    item-text="descricao"
                    item-value="id_tipo_dado"
                    v-model="form.id_tipo_dado"
                    clearable
                    single-line
                    :rules="[rules.required]"
                    :filters="{ ativa_comissao: 'S' }"
                    @change="fetchTipoFechamento()"
                  />
                </v-col>
                <!-- Tipo Fechamento -->
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    label="Tipo Fechamento"
                    v-model="form.id_tipo_fechamento"
                    :items="tipoFechamentoItems"
                    item-text="textTipoFechamento"
                    item-value="id_tipo_fechamento"
                    :value-comparator="comparator"
                    prepend-inner-icon="mdi-calendar-end"
                    single-line
                    :rules="[rules.required]"
                    clearable
                    chips
                    :disabled="form.id_tipo_dado == undefined"
                    :multiple="false"
                  >
                  </v-autocomplete>
                </v-col>

                <!-- Valor -->
                <v-col cols="12" sm="4">
                  <vuetify-money
                    :rules="[rules.required]"
                    label="Valor"
                    valueWhenIsEmpty="0"
                    :options="optionsVuetifyMoney"
                    v-model="form.valor"
                    prepend-inner-icon="mdi-currency-usd"
                  ></vuetify-money>
                </v-col>

                <v-col cols="12" sm="8">
                  <v-text-field
                    label="Obs. Lançamento"
                    v-model="form.obs_lanc"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="labelBtn != 'Cadastrar' ? update() : store()"
            >{{ labelBtn }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import BaseSelect from "@/components/shared/BaseSelectService";
import comissao from "@/services/http/comissaoService";
import comparator from "@/mixins/comparatorValue";
import rules from "@/mixins/inputRules";

export default {
  name: "ModalCadastroRealizadosIndividual",

  components: {
    BaseSelect
  },

  mixins: [rules, comparator],

  props: {
    dialog: {
      type: Boolean
    },
    labelBtn: {
      type: String
    },
    dataForm: {
      type: Object
    }
  },

  data() {
    return {
      form: {},
      tipoFechamentoItems: [],
      tipoLancamentoItems: [
        {
          value: "A",
          text: "Automático"
        },
        {
          value: "M",
          text: "Manual"
        }
      ],
      optionsVuetifyMoney: {
        locale: "pt-BR",
        prefix: "R$",
        length: 11,
        precision: 2
      }
    };
  },

  watch: {
    dataForm(value) {
      this.form = value;
    },

    labelBtn(value) {
      return value;
    }
  },
  computed: {
    ...mapGetters({
      getUser: "getUser"
    })
  },

  methods: {
    async fetchTipoFechamento() {
      const eventoComissao = "C";
      let { data } = await comissao()
        .tipoFechamento()
        .show({
          per_page: -1,
          id_tipo_evento: eventoComissao,
          id_tipo_dado: this.form.id_tipo_dado
        });
      this.tipoFechamentoItems = data.data.map(item => {
        item["textTipoFechamento"] = `${this.converteDate(
          item.data_inicio
        )} - ${this.converteDate(item.data_final)}`;
        return item;
      });
    },

    async store() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        this.form.usuario_lanc = this.getUser.name;
        this.form.autorizado = "N";
        await comissao()
          .lancamentoManual(this.form.id_lanc_manual)
          .store(this.form, { notification: true });
        this.close();
      }
    },

    async update() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .lancamentoManual(this.form.id_lanc_manual)
          .update(this.form, {
            notification: true,
            message: "Lançamento editado com sucesso!"
          });
        this.close();
      }
    },

    converteDate(date) {
      //in: 2020-06-30 00:00:00
      //out: 30/06/2020
      return date
        .substring(0, 10)
        .split("-")
        .reverse()
        .join("-")
        .replaceAll("-", "/");
    },

    close() {
      this.$emit("closeModal");
      this.$refs.form.reset();
    }
  },

  async mounted() {
    await this.fetchTipoFechamento();
  }
};
</script>

<style></style>
