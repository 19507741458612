<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="30%" persistent>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="toolbar-color">
            <span class="headline ml-2">
              Autoriza Lançamento
            </span>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    label="Status"
                    v-model="form.autorizado"
                    :items="[
                      { text: 'Aprovado', value: 'S' },
                      { text: 'Negado', value: 'N' }
                    ]"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Obs. Auto"
                    v-model="form.obs_auto"
                    :rules="[rules.required]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
          <v-btn color="green darken-1" text @click="processaDado()"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import comissao from "@/services/http/comissaoService";
import rules from "@/mixins/inputRules";
export default {
  mixins: [rules],

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    dataForm: {
      type: Object
    }
  },

  data() {
    return {
      form: {}
    };
  },

  watch: {
    dataForm(value) {
      return value;
    }
  },

  computed: {
    ...mapGetters({
      getUser: "getUser"
    })
  },

  methods: {
    async processaDado() {
      if (!this.$refs.form.validate()) return;
      this.form.usuario_autoriu = this.getUser.name;
      await comissao()
        .lancamentoManual(this.dataForm.id_lanc_manual)
        .update(this.form, { notification: true });
      this.close();
    },

    close() {
      this.$emit("closeModal");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
